import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Text from "../components/text"
import Contact from "../components/contact"
import Footer from "../components/footer"

const Ptsd = () => (
  <Layout>
    <SEO
      lang="it"
      title="Disturbo da stress post traumatico' | Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="Descrizione del disturbo da stress post traumatico di Stefano Maffina, psicologo clinico"
    />
    <div className="navbar paddings-lr">
      <div style={{ width: "1050px", margin: "0 auto", paddingLeft: "16px" }}>
        <Link to="/">
          <span>Indietro</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#fff">
      <div style={{ paddingTop: "64px" }}>
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            <span style={{ fontWeight: 700, textDecoration: "italic" }}>
              Disturbo post-traumatico da stress
            </span>{" "}
          </h2>
        </div>
        <div className="paddings-lr why-content">
          <p>
            E' un problema psicologico caratterizzato da un evento traumatico.
          </p>
          <p>
            Le caratteristiche principali sono: riattivazione dell’evento
            traumatico, evitamento degli stimoli ad esso correlati e attivazione
            corporea cronicamente elevata.{" "}
          </p>
          <p>
            Nel disturbo sono centrali le accresciute preoccupazioni riguardanti
            minacce alla sicurezza personale, ad esempio la morte. Avviene una
            riattivazione degli aspetti salienti degli eventi traumatici
            innescati da stimoli relativi al trauma, che comportano un aumento
            dell’attività somatica e comportamenti di terrore estremo (incubi e
            flashback).
          </p>
        </div>
      </div>
    </Section>
    <Contact />
    <Footer />
  </Layout>
)

export default Ptsd
