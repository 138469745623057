import React from "react"
import "./styles.scss"

const text = ({ text, isDark, noMargin }) => (
  <div data-aos="fade-in" data-aos-duration="1000" className="text-container">
    <span
      style={{
        color: isDark ? "#AFADB6" : "#777",
        paddingBottom: noMargin ? "0px" : "24px",
      }}
    >
      {text}
    </span>
  </div>
)

export default text
